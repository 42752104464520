import React, { useEffect, useState, useRef } from "react";
import './App.css';
//import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { Button, CssBaseline, Typography,createTheme
  , ThemeOptions, ThemeProvider, AppBar, Container, Fab
  , Link, CircularProgress, Backdrop, Avatar, Paper
  ,Box, Grid, 
 } from '@mui/material';
 import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ResponsiveAppBar from './Components/respAppBar';
import Iframe from 'react-iframe'
import Autorenew from '@mui/icons-material/Autorenew';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import aboutbackground from './aboutbackground.png'
import alglogo from './alglogo.png';
import GA4React, { useGA4React } from "ga-4-react";
const ga4react = new GA4React("G-56S22W1F83");
// ga4react.initialize().then().catch()

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function App() {
  const ga = useGA4React();
  console.log(ga);
  return <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/allstreams" element={<AllStreams />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </Router>;
}


function About() {
  const ga = useGA4React();
  console.log(ga);
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#27AB83',
      },
      secondary: {
        main: '#4098D7',
      },
      background: {
        default: '#F0F4F8',
        paper: '#fbfbfb',
      },
    },
    typography: {
      body1: {
        fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      },
      body2: {
        fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      },
      button: {
        fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      },
      caption: {
        fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      },
      overline: {
        fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      },
      subtitle1: {
        fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      },
      subtitle2: {
        fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      },
    },
    props: {
      MuiAppBar: {
        color: 'transparent',
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${aboutbackground})`,//'url(https://source.unsplash.com/random)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            
            {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <ImportContactsIcon />
            </Avatar> */}
            <Link  href={'/'} style={{textDecoration:'none'}}>
            <img src={alglogo} alt="logo" height="100px"/>
            <Typography component="h1" variant="h5">
              About
            </Typography>
            </Link>

            <Typography align="center" component="article" variant="body1">
            Welcome to our website, where we bring the beauty of nature right to your screen!
            </Typography>
            <Typography align="center" component="article" variant="body1">
               <p></p>
            </Typography>
             <Typography align="center" component="article" variant="body1">
Our team is dedicated to finding the most breathtaking landscapes and fascinating animal behavior to share with you. We've traveled to the ends of the earth to capture it all, from the icy tundras of the Arctic to the lush rainforests of the Amazon.
</Typography>
<Typography align="center" component="article" variant="body1">
               <p></p>
            </Typography>
<Typography align="center" component="article" variant="body1">
But let's be real, the real stars of the show are the monkeys. Those clever little critters never cease to amaze us with their antics and problem-solving skills. In fact, some of our most popular streams have been of monkey communities going about their daily lives. It's hard not to see a little bit of ourselves in these intelligent primates.
</Typography>
<Typography align="center" component="article" variant="body1">
               <p></p>
            </Typography>
<Typography align="center" component="article" variant="body1">
So sit back, relax, and let us transport you to the great outdoors. Who knows, you might even learn a thing or two about our simian friends and the natural world. Thanks for joining us!
            </Typography>
            <Typography align="center" component="article" variant="body1">
               <p></p>
            </Typography>
            <Typography align="center" component="article" variant="caption">
               Generated by AI
            </Typography>
            <Link  href={'/'} style={{textDecoration:'none'}}>
            <Typography align="center" component="article" variant="caption">
               Back home
            </Typography></Link>
            
            <Button 
                       target="_blank" rel="noreferrer"
        href='https://www.chidwi.com'>
               created by chidwi
            </Button>
            <Box component="form" sx={{ mt: 1 }}>
              
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

function AllStreams() {
  return (
    <div style={{ padding: 20 }}>
      <h2>About View</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adip.</p>
    </div>
  );
}

function Home() {
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#27AB83',
      },
      secondary: {
        main: '#4098D7',
      },
      background: {
        default: '#F0F4F8',
        paper: '#fbfbfb',
      },
    },
    typography: {
      body1: {
        fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      },
      body2: {
        fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      },
      button: {
        fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      },
      caption: {
        fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      },
      overline: {
        fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      },
      subtitle1: {
        fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      },
      subtitle2: {
        fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      },
    },
    props: {
      MuiAppBar: {
        color: 'transparent',
      },
    },
  });

  const { height, width } = useWindowDimensions();
  const n = height-120
  const streams = [
   'https://www.youtube.com/embed/48MFrf5ADp8&autoplay=1'
  ,'http://www.jigokudani-yaenkoen.co.jp/livecam2/video_en.php' // Snow Monkey - Japan
  ,'https://www.youtube.com/embed/sqAdEAH2q_g?a&autoplay=1' //Tau Waterhole - South Africa
  ,'https://www.youtube.com/embed/gUZjDCZEMDA?a&autoplay=1' //Nkorho Bush Lodge - South Africa
  ,'https://www.youtube.com/embed/rgXWDk7rh4w?a&autoplay=1' //Grace Gorilla Forest Corridor - Congo
  ,'https://www.youtube.com/embed/48MFrf5ADp8?a&autoplay=1' // Tembe Elephant Park - South Africa
  ,'https://www.youtube.com/embed/wXYQo09Y2kc?a&autoplay=1' // Olifants River - South Africa
  ,'https://www.youtube.com/embed/TL0weAv8C9s?a&autoplay=1' // Rosie's Pan - South Africa
  ,'https://www.youtube.com/embed/6NIt6ibAD6I?a&autoplay=1' // Mpala Watering Hole - Kenya
  ,'https://www.youtube.com/embed/8UFA66bi-0g?a&autoplay=1' // Naledi Cat-EYE - South Africa
  ,'https://www.youtube.com/embed/9viZIxuonrI?a&autoplay=1' // Lisbon Falls - South Africa
  ,'https://www.youtube.com/embed/0h24K8w1pws?a&autoplay=1' // Mpala Watering Hole Beach - Kenya
  ,'https://www.youtube.com/embed/3MlJEXOZTfo?a&autoplay=1' //Lessor Flamingos at Kamfers Dam - South Africa
  ,'https://www.youtube.com/embed/tC-J27EF800?a&autoplay=1' // cape West W[usk National Park - Canada
  ,'https://www.youtube.com/embed/pHCaRGbvXtE?a&autoplay=1' // Naknek River, USA
  ,'https://www.youtube.com/embed/RmmAzrAkKqI?a&autoplay=1' //Catalina Island - USA
  ,'https://www.youtube.com/embed/og8bbxl0iW8?a&autoplay=1' // Cape Fear Frying Pan Tower - USA
  ,'https://www.youtube.com/embed/CwriDd8STdI?a&autoplay=1' //Churchill Northern Studies Center - Canada
  ,'https://www.youtube.com/embed/XSRKzMHX71c?a&autoplay=1' //Shibuya Crossing - Japan [Human]
  ,'https://www.youtube.com/embed/mfliIqaZddU?a&autoplay=1' // Rainbow Bridge - Japan [Human]
  ,'https://www.youtube.com/embed/-HxJYZI9AMk?a&autoplay=1' // Ol Donyo - Kenya
  ,'https://www.youtube.com/embed/zSL7F4SPXOA?a&autoplay=1' // Finse Railroad Station - Norway [Human]
  ,'https://www.youtube.com/embed/SPj4hMttgJM?a&autoplay=1' // Elvis' Beach Bar - Anguilla [HUman]
  ,'https://www.youtube.com/embed/1-iS7LArMPA?a&autoplay=1' // Times Square - USA [Human]
  ,'https://www.youtube.com/embed/SQfE_-sSdc4?a&autoplay=1' // International Wolf Center North - USA
  ,'https://www.youtube.com/embed/cR_qkHpBHTI?a&autoplay=1' //  International Wolf Center South -  USA
  ,'https://www.youtube.com/embed/Kv2GVVzYbMk?a&autoplay=1' // Atlanta Zoo Pandas - USA
  ,'https://www.youtube.com/embed/JQnxefImhu8?a&autoplay=1' // Sea Otter - USA
  ,'https://www.youtube.com/embed/pT9_HJr-nso?a&autoplay=1' // Jelly Cam - USA
  ,'https://www.youtube.com/embed/TKZ8I22XU0w?a&autoplay=1' // Moon Jelly - USA
  ,'https://www.youtube.com/embed/xmvDYRsf5cs?a&autoplay=1' //Monterey Bay - USA
  ,'https://www.youtube.com/embed/_Ufi4IUjljk?a&autoplay=1' // Kelp Forest - USA
  ,'https://www.youtube.com/embed/6-JYR4DX39Q?a&autoplay=1' // Shark Cam - USA
  ,'https://www.youtube.com/embed/DI6pwwfXCYo?a&autoplay=1' // African Penguins - USA
  ,'https://www.youtube.com/embed/rc1lmCbrcf4?a&autoplay=1' // Aviary Cam - USA
  ,'https://www.youtube.com/embed/bm8KkdAfRTs?a&autoplay=1' // Melvin Brewing - USA
  ,'https://www.youtube.com/embed/i5qNAHxA2Uw?a&autoplay=1' // Garden Cam - USA
  ,'https://www.youtube.com/embed/PviDO3ZVwwE?a&autoplay=1' // bird feed - Czech Republic
  ,'https://www.youtube.com/embed/wP6hpWgPJ98?a&autoplay=1' // Resrt - Thailan
  ,'https://www.youtube.com/embed/DY5RYp4sxYc?a&autoplay=1' // Banzai Pipeline - USA
  ,'https://www.youtube.com/embed/GwpYqOi7M0s?a&autoplay=1' // Manatee - USA
  ,'https://www.youtube.com/embed/hnap-BBZhsA?a&autoplay=1'// Cruz da Caldeira - Portugal
  ,'https://www.youtube.com/embed/QPZd3gEWn-8?a&autoplay=1'// Koh Chang - Thailand
  ,'https://www.youtube.com/embed/_VpoLPsBZgk?a&autoplay=1'// Deer Pantry - USA
  ,'https://www.youtube.com/embed/F1Q7iN_RWUs?a&autoplay=1'//Pix Wildlife  -USA
  ,'https://www.youtube.com/embed/JEYBoQVGrjc?a&autoplay=1' //Gardens Adachi - Japan [Human]
  ,'https://www.youtube.com/embed/NTbhpC3C5MY?a&autoplay=1' //Grand Targhee Resort - USA [Human]
  ,'https://www.youtube.com/embed/dsbHMpqPTWI?a&autoplay=1'//Cruz Bay - USA
  ,'https://www.youtube.com/embed/ndhYKVzCIs8?a&autoplay=1' //Hellesylt - Norway [Human]
  ,'https://www.youtube.com/embed/-AmaIGvwt1c?a&autoplay=1' //Hula Bay - Israel
  ,'https://www.youtube.com/embed/blzBDRwyjlg?a&autoplay=1' //Lake Jasna - Slovenia
  ,'https://www.youtube.com/embed/tYIq_uGPJ9s?a&autoplay=1' //Laguna de El Oso - Spain
  ,'https://www.youtube.com/embed/V3aTpIxIGDg?a&autoplay=1' //Liberty bear Sanctury - Romania
  ,'https://www.youtube.com/embed/3r52UXK-1QY?a&autoplay=1' //Mill River - USA
  ,'https://www.youtube.com/embed/bxhYlfFRvhQ?a&autoplay=1' // Parc de la Chute - Canada
  ,'https://www.youtube.com/embed/ydYDqZQpim8?a&autoplay=1' // Namib Desert Lode - Namibia
  ,'https://www.youtube.com/embed/4Hro9QIrsYA?a&autoplay=1' // Mt Fuji - Japan
  ,'https://www.youtube.com/embed/kxwuFPFUZyY?a&autoplay=1' //Niagra Falls - Canada
  ,'https://www.youtube.com/embed/CwriDd8STdI?a&autoplay=1' //Northern Lights - Canada
  ,'https://www.youtube.com/embed/teOuSoiZ-nY?a&autoplay=1' //Paul Do Mar - Portugal
  ,'https://www.youtube.com/embed/rI5i0CtKBzU?a&autoplay=1' //Quinta Do Furao - Portugal
  ,'https://www.youtube.com/embed/7RuQidGxLD4?a&autoplay=1' // Santiago Peak - USA
  ,'https://www.youtube.com/embed/3AhU4uulyIc?a&autoplay=1' // Elk Refuge Live - USA
  ,'https://www.youtube.com/embed/Ef_cxstkp-0?a&autoplay=1' //Valley of Valldalen - Norway [Human]
  ,'https://www.youtube.com/embed/2NS-5SqvhkI?a&autoplay=1' //Xiao Wulai Skywalk - Taiwan [Human]
  ,'https://www.youtube.com/embed/qYC8DpGOIJc?a&autoplay=1' //Xiao Wulai - Tawiwan
  ,'https://www.youtube.com/embed/9GPMcqWhgKA?a&autoplay=1' //Yubatake hot spring - Japan [Human]
  ,'https://www.youtube.com/embed/Xhk8H-pb9i8?a&autoplay=1' // Yyteri beach - Finland
  ,'https://www.youtube.com/embed/86YLFOog4GM?a&autoplay=1' // ISS - World
]
  const [currentStream, setCurrentStream] = useState(streams[Math.floor(Math.random()*streams.length)])
  const [refreshingStream, setRefreshingStream] = useState(false)
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ResponsiveAppBar />
      <Container maxWidth="xl" 
      style={{flex: 1,justifyContent: 'center',
              position:'relative', overflow:'hidden',
              alignItems: 'center' , paddingBottom:40,
              // backgroundImage:`url(${simpsonstv})`,
              // backgroundPosition: 'center',
              // backgroundSize: 'cover',
              // backgroundRepeat: 'no-repeat',
              // width: '100vw',
              // height: '100vh'
              }}>
                
        <div style={{position:"absolute", bottom:0, left:20,
                       right:20, 
                       justifyContent:'center', alignContent:'center',
                       alignItems:'center'}}>
          
        <Button    style={{position:"absolute", right:20, bottom:0
                       }}
                       //variant="extended" 
                       color="primary" aria-label="newstream"
                       variant="contained"
                       
                       onClick={() => {
                         setCurrentStream(streams[Math.floor(Math.random()*streams.length)])
                         setRefreshingStream(true)
                         setTimeout(function(){
                          setRefreshingStream(false);
                        }, 2000);
                      }}
                      >
          <Autorenew /> New Stream
        </Button> 
        <Button style={{position:"absolute", left:20, bottom:0
                       }}
                       target="_blank" rel="noreferrer"
        href={currentStream.replace("/embed/", "/watch?v=" )}><OpenInNewIcon />Direct stream link </Button>
        </div>
        <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={refreshingStream}
  onClick={() => {}}
> <CircularProgress color="inherit" />
<p> Fetching new stream </p>
  <CircularProgress color="inherit" />
  
</Backdrop> 
        <Iframe url={currentStream}
          height={n}
          width="100%"
          id=""
          className=""
          style={{top: 0, bottom:0, left:0, right:0}}
          allow="autoplay"
          />

{/* <ReactHlsPlayer
    src="https://worldcams.tv/player?url=https://stream.resortcams.com/live-hogsbreath/hogsbreath2-audio.stream/playlist.m3u8"
    autoPlay={false}
    controls={true}
    width="100%"
    height="auto"
  /> */}
        
        {/* <Button variant="contained" color="primary">I'm Feeling Lucky</Button> */}
        
      </Container>
      
    </ThemeProvider>
  );
}


export default App;
